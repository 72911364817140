<script setup>
import moment from 'moment'
import { computed, ref } from 'vue'
import { VisuallyHidden } from 'radix-vue'
import { useElementVisibility } from '@vueuse/core'
import { Link, router } from '@inertiajs/vue3'
import {
  Button,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
} from '@codinglabsau/gooey'
import {
  EllipsisHorizontalIcon,
  PencilSquareIcon,
  PhotoIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/vue/24/outline'
import {
  Container,
  Dropdown,
  DropdownItem,
  Pagination,
  PrimaryButton,
  SecondaryButton,
} from '@codinglabsau/ui'
import { ship_image } from '@/helpers'
import SubSection from '@/Components/SubSection.vue'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import ClaimCard from '@/Components/Claims/ClaimCard.vue'
import ClaimModal from '@/Components/Claims/ClaimModal.vue'
import ClaimInfoModal from '@/Components/Claims/ClaimInfoModal.vue'
import SupporterCTA from '@/Components/Supporters/SupporterCTA.vue'
import SubSectionTitle from '@/Components/Section/SubSectionTitle.vue'
import SupporterBadge from '@/Components/Supporters/SupporterBadge.vue'
import LocalPageNavigationItem from '@/Components/LocalPageNavigationItem.vue'
import ConvictSummarySection from '@/Components/Convicts/ConvictSummarySection.vue'
import LocalPageNavigationDropdown from '@/Components/LocalPageNavigationDropdown.vue'
import EditConvictNotesModal from '@/Components/Contributions/ConvictNote/EditConvictNotesModal.vue'
import DeleteConvictNotesModal from '@/Components/Contributions/ConvictNote/DeleteConvictNotesModal.vue'
import CreateConvictNotesModal from '@/Components/Contributions/ConvictNote/CreateConvictNotesModal.vue'

const props = defineProps({
  convict: Object,
  convictNotes: Array,
  changelog: Object,
  claim: Object,
  claims: Object,
})

const deleteNoteModal = ref(false)
const createNoteModal = ref(false)
const editNoteModal = ref(false)

const convictNote = ref(null)

const selectedFile = ref(null)

const heading = ref(null)
const headingIsVisible = useElementVisibility(heading)

const openDeleteNoteModal = (note) => {
  convictNote.value = note
  deleteNoteModal.value = true
}

const openEditNoteModal = (note) => {
  convictNote.value = note
  editNoteModal.value = true
}

const disclaim = () => {
  router.delete(route('convicts.disclaim', props.claim?.id), {
    preserveScroll: true,
  })
}

const menu = computed(() =>
  [
    'Summary',
    'Transportation',
    'References',
    'Claims',
    'Convict Notes',
    'Photos',
    'Revisions',
  ].filter((option) => props.convict.voyage || option !== 'Transportation')
)
</script>

<template>
  <HeadMetaTags
    :title="`Convict ${convict.name}`"
    :description="`A history of the convict ${convict.name}.`"
    :canonical="route('convicts.show', convict.slug)"
    :url="route('convicts.show', convict.slug)"
  />

  <Container class="lg:flex lg:flex-row-reverse">
    <!-- desktop sidebar nav -->
    <aside class="hidden w-96 border-l bg-white p-4 lg:block lg:px-8">
      <nav class="prose sticky top-2 flex flex-col font-serif font-extralight text-black">
        <span class="prose-lg font-serif font-bold">Contents</span>
        <LocalPageNavigationItem v-for="name in menu" :key="name" :target-id="name">
          {{ name }}
        </LocalPageNavigationItem>
      </nav>
    </aside>

    <!-- mobile dropdown nav -->
    <nav
      v-show="!headingIsVisible"
      class="sticky top-0 z-20 border-b border-zinc-200 bg-white/85 p-2 backdrop-blur lg:hidden"
    >
      <div class="flex items-center gap-x-2">
        <LocalPageNavigationDropdown>
          <span class="prose-lg font-serif font-bold">Contents</span>
          <LocalPageNavigationItem v-for="name in menu" :key="name" :target-id="name">
            {{ name }}
          </LocalPageNavigationItem>
        </LocalPageNavigationDropdown>

        <div>
          {{ convict.name }}
        </div>
      </div>
    </nav>

    <article class="space-y-12 bg-white p-4 sm:px-8">
      <div ref="heading" class="flex flex-wrap items-center justify-between space-y-1">
        <h1
          class="whitespace-nowrap font-serif text-2xl font-bold leading-6 text-stone-600 md:text-3xl"
        >
          {{ convict.name }}
        </h1>

        <div class="flex items-center gap-x-1">
          <PrimaryButton
            v-if="convict.approved === '1'"
            :href="route('convicts.edit', convict)"
            rel="nofollow"
          >
            Edit
          </PrimaryButton>
        </div>
      </div>

      <SubSection title="Summary" class="!mt-4">
        <ConvictSummarySection :convict="props.convict" />
      </SubSection>

      <SubSection v-if="convict.voyage" title="Transportation">
        <section class="flex items-start justify-between gap-x-4">
          <div>
            <div>
              <strong class="font-light text-gray-500"> Ship: </strong>
              <Link
                :href="route('ships.show', convict.voyage.ship.url_name)"
                class="text-link hover:text-link-hover hover:underline"
              >
                {{ convict.voyage.ship.name }}
              </Link>
            </div>

            <div>
              <strong class="font-light text-gray-500"> Departed: </strong>
              {{
                convict.voyage.departure_date
                  ? moment(convict.voyage.departure_date).format('Do MMM, YYYY')
                  : 'Unknown'
              }}
            </div>

            <div>
              <strong class="font-light text-gray-500"> Arrived: </strong>
              {{
                convict.voyage.arrival_date
                  ? moment(convict.voyage.arrival_date).format('Do MMM, YYYY')
                  : 'Unknown'
              }}
            </div>

            <div>
              <strong class="font-light text-gray-500"> Passengers: </strong>
              {{ convict.voyage.passenger_count }}
            </div>

            <p class="mt-4 max-h-96 overflow-hidden whitespace-pre-line break-words">
              {{ convict.voyage.ship.description }}
            </p>
          </div>

          <img
            :src="ship_image(convict.voyage.ship.image)"
            :alt="convict.voyage.ship.name"
            class="size-32 rounded-full"
          />
        </section>
      </SubSection>

      <SubSection title="References">
        <div class="overflow-x-auto">
          <table class="w-full border-collapse text-left">
            <tbody class="bg-gray-50 text-black">
              <tr>
                <td class="flex p-2 sm:whitespace-nowrap">Primary Source</td>
                <td class="p-2">{{ convict.source }}</td>
              </tr>
              <tr v-if="convict.source_description">
                <td class="flex p-2 sm:whitespace-nowrap">Source Description</td>
                <td class="p-2">{{ convict.source_description }}</td>
              </tr>
              <tr v-if="convict.original_source">
                <td class="flex p-2 sm:whitespace-nowrap">Original Source</td>
                <td class="p-2">{{ convict.original_source }}</td>
              </tr>
              <tr v-if="convict.compiled_by">
                <td class="flex p-2 sm:whitespace-nowrap">Compiled By</td>
                <td class="p-2">{{ convict.compiled_by }}</td>
              </tr>
              <tr v-if="convict.database_source">
                <td class="flex p-2 sm:whitespace-nowrap">Database Source</td>
                <td class="p-2">{{ convict.database_source }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </SubSection>

      <SubSection>
        <template #title>
          <ClaimInfoModal>
            <template #trigger>
              <div class="flex">
                <SubSectionTitle title="Claims" />
                <QuestionMarkCircleIcon class="size-5 ml-1 cursor-pointer my-auto" />
              </div>
            </template>
          </ClaimInfoModal>
        </template>
        <template #actions>
          <template v-if="convict.approved === '1'">
            <SecondaryButton v-if="claim" as="button" rel="nofollow" @click.prevent="disclaim">
              Remove Claim
            </SecondaryButton>

            <ClaimModal v-else-if="claims.data.length > 0" :convict="convict" />
          </template>
        </template>

        <div v-if="claims.data.length > 0" class="space-y-4">
          <SupporterCTA reason="Become a supporter to message these people." />

          <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-4">
            <ClaimCard v-for="claimData in claims.data" :claim="claimData" />
          </div>

          <Pagination :collection="claims" />
        </div>
        <div
          v-else
          class="rounded-md text-center p-4 text-gray-500 border border-dashed border-gray-300"
        >
          <p>
            No one has claimed <b> {{ convict.name }} </b> yet.
          </p>

          <div v-if="convict.approved === '1'" class="mt-2">
            <ClaimModal :convict="convict" />
          </div>
        </div>
      </SubSection>

      <SubSection title="Convict Notes">
        <template #actions>
          <CreateConvictNotesModal
            v-if="$page.props.auth.user"
            v-model:open="createNoteModal"
            :convict="convict"
          >
            <template #button>
              <Button variant="outline" @click.prevent="createNoteModal = true">
                <PencilSquareIcon class="-ml-0.5 mr-1.5 size-5" aria-hidden="true" />
                Add Note
              </Button>
            </template>
          </CreateConvictNotesModal>
        </template>

        <div class="whitespace-pre-line break-words leading-loose">
          <section v-if="convictNotes.length > 0">
            <section v-for="notes in convictNotes" :key="notes.id" class="border-b-2 py-3">
              <div class="flex justify-between">
                <div>
                  <span class="font-light text-gray-500"> Contributed by </span>
                  <span>{{ notes.contributor?.name ?? 'State Library of Queensland' }} </span>
                  <span class="font-light text-gray-500"> on </span>
                  <span>
                    {{ moment(notes.created_at).format('Do MMMM YYYY') }}
                  </span>
                </div>
                <div v-if="$page.props.auth.user?.id === notes.contributor_id" class="space-x-1">
                  <Dropdown>
                    <template #text>
                      <EllipsisHorizontalIcon class="size-5" />
                    </template>
                    <DropdownItem @click.prevent="openEditNoteModal(notes)"> Edit</DropdownItem>
                    <DropdownItem @click.prevent="openDeleteNoteModal(notes)">
                      Delete
                    </DropdownItem>
                  </Dropdown>
                </div>
              </div>
              <p class="prose whitespace-break-spaces pt-4">{{ notes.note }}</p>
            </section>
            <DeleteConvictNotesModal
              v-model:open="deleteNoteModal"
              :convict-note="convictNote"
              hidden-button
            />
            <EditConvictNotesModal
              v-model:open="editNoteModal"
              :convict-note="convictNote"
              hidden-button
            />
          </section>
          <section
            v-else
            class="rounded-md text-center p-4 text-gray-500 border border-dashed border-gray-300"
          >
            <p>
              No notes have been added for <b> {{ convict.name }} </b>.
            </p>
          </section>
        </div>
      </SubSection>

      <SubSection title="Photos">
        <template #actions>
          <Link
            v-if="$page.props.auth.user?.supporter"
            rel="nofollow"
            :href="route('convicts.files.edit', convict)"
          >
            <Button variant="outline">
              <PhotoIcon class="-ml-0.5 mr-1.5 size-5" aria-hidden="true" />
              Manage Photos
            </Button>
          </Link>
        </template>

        <template v-if="convict.files.length > 0">
          <!-- Convict has photos -->
          <SupporterCTA reason="Become a supporter to manage photos for this convict." />
          <div class="px-12">
            <Carousel
              class="relative w-full"
              :opts="{
                align: 'start',
              }"
            >
              <CarouselContent>
                <CarouselItem
                  v-for="(file, index) in convict.files"
                  :key="index"
                  class="md:basis-1/2 lg:basis-1/3"
                  @click="selectedFile = file"
                >
                  <div class="flex aspect-square items-center justify-center p-6">
                    <img :src="file.thumb" alt="Convict photo" class="object-cover size-full" />
                  </div>
                </CarouselItem>
              </CarouselContent>
              <CarouselPrevious />
              <CarouselNext />
            </Carousel>
          </div>
        </template>
        <template v-else>
          <div
            class="rounded-md text-center p-4 text-gray-500 border border-dashed border-gray-300"
          >
            <p>
              No photos have been added for <b> {{ convict.name }} </b>.
            </p>
          </div>

          <SupporterCTA reason="Become a supporter to manage photos for this convict." />
        </template>
      </SubSection>

      <SubSection title="Revisions">
        <table v-if="changelog" class="w-full border-collapse text-left">
          <thead>
            <tr class="bg-white text-black">
              <th class="p-2">Contributor</th>
              <th class="p-2">Date</th>
              <th class="p-2">Changes</th>
            </tr>
          </thead>
          <tbody class="bg-gray-50 text-black">
            <tr v-for="log in changelog" :key="log.id">
              <td class="items-center pr-2">
                <div class="flex items-center gap-x-1">
                  <SupporterBadge v-if="log.contributor?.supporter" class="size-6" />
                  {{ log.contributor ? log.contributor.name : 'Anonymous' }}
                </div>
              </td>
              <td class="w-1/5 whitespace-nowrap p-2">
                {{ moment(log.updated_at).format('Do MMM YYYY') }}
              </td>
              <td class="w-2/5 p-2">{{ log.changelog ? log.changelog : 'none' }}</td>
            </tr>
          </tbody>
        </table>

        <section v-else class="bg-gray-50 px-2 py-3">
          No revisions have been made to {{ convict.name }}.
        </section>
      </SubSection>
    </article>

    <Dialog :open="selectedFile !== null">
      <DialogContent
        class="overflow-hidden rounded border-none bg-black p-0 text-white"
        @close="selectedFile = null"
        @interact-outside="selectedFile = null"
      >
        <VisuallyHidden>
          <DialogTitle>View Photo</DialogTitle>
        </VisuallyHidden>
        <DialogClose class="absolute right-2 top-2 size-8 rounded-full bg-black/25" />
        <template v-if="selectedFile">
          <img
            v-if="selectedFile"
            :src="selectedFile.image"
            alt="Convict photo"
            class="size-full max-h-[80vh] object-contain"
          />
          <div
            v-if="selectedFile.description"
            class="absolute inset-x-0 bottom-0 bg-black/50 p-3 text-sm italic backdrop-blur-sm"
          >
            <p class="text-white">{{ selectedFile.description }}</p>
          </div>
        </template>
      </DialogContent>
    </Dialog>
  </Container>
</template>
